import { Component } from '@angular/core';
import { FrameworkConfigService, FrameworkConfigSettings } from "fw/services/framework-config.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor (private frameworkConfigService: FrameworkConfigService) {

      let config:FrameworkConfigSettings = {
        socialIcons: [
        ],
        showLanguageSelector: true,
        showUserControls: true,
        showStatusBar: true,
        showStatusBarBreakpoint: 800
      };

      frameworkConfigService.configure(config);
  }
}

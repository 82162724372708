import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentComponent } from "app/components/payment/payment.component";
import { ConfirmationComponent } from "app/components/confirmation/confirmation.component";
import { AuthorizeGuard } from "app/guards/authorize.guard";
import { ErrorComponent } from "app/components/error/error.component";

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'payment' },
  { path: 'pmt/:reqnum', component: PaymentComponent },
  { path: 'confirmation/:reqnum', component: ConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div *ngIf="pmtSuccess == true" class="row center-block">
  <span><img src="/assets/img/confirm.png" class="img-responsive confirm-text"/>
    <h3 class="confirm-text"><b>Your payment has been submitted successfully!!!</b></h3></span>
</div>
<div *ngIf="pmtSuccess == false" class="row center-block">
  <span><img src="/assets/img/warning.png" class="img-responsive confirm-text"/>
    <h3 class="confirm-text"><b>Failed to submit payment info!!!</b></h3></span>
</div>
<div *ngIf="authPass==false" class="row center-block" align="center">
  <span><img src="/assets/img/warning.png" class="img-responsive confirm-text"/>
    <h3 class="confirm-text"><b>Invalid URL!!!</b></h3></span>
</div>
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: 'false',
  envName: 'ps',
  version: '11.4.0',
  msgurl: "http://www.themadisonsquaregardencompany.com/",
  requestixapiurl: 'https://requestix-api-ps.msg.com/',
  requestixapikey: '5eb2a135ae874470878544106dbf7f8d'
};

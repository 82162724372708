import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {

  constructor() { }
  // Visa: length 16, prefix 4, dashes optional.
  visaRe: RegExp = /^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/;
  // Mastercard: length 16, prefix 51-55, dashes optional.
  mcRe: RegExp = /^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$/;
  // American Express: length 15, prefix 34 or 37.
  aRe: RegExp = /^3[4,7]\d{13}$/;
  // Discover: length 16, prefix 6011, dashes optional.
  dRe: RegExp = /^6011-?\d{4}-?\d{4}-?\d{4}$/;

  validateCard(cardNum) {
    if (cardNum.match(this.visaRe) == null && cardNum.match(this.mcRe) == null
      && cardNum.match(this.aRe) == null && cardNum.match(this.dRe) == null) {
      return false;
    }

    // Checksum ("Mod 10")
    // Add even digits in even length strings or odd digits in odd length strings.
    var checksum = 0;
    for (var i = (2 - (cardNum.length % 2)); i <= cardNum.length; i += 2) {
      checksum += parseInt(cardNum.charAt(i - 1));
    }
    // Analyze odd digits in even length strings or even digits in odd length strings.
    for (var j = (cardNum.length % 2) + 1; j < cardNum.length; j += 2) {
      var digit = parseInt(cardNum.charAt(j - 1)) * 2;
      if (digit < 10) { checksum += digit; } else { checksum += (digit - 9); }
    }
    if ((checksum % 10) === 0) return true; return false;
  }

  validateExpiry(month, year, eventdate) {
    var currentDate = new Date(eventdate);
    var expiry = new Date(year, month);
    return currentDate <= expiry;
  }

  GetCardType(cardNum) {
    if (cardNum.match(this.visaRe) != null) {
      return "VI";
    }
    else if (cardNum.match(this.mcRe) != null) {
      return "MC";
    }
    else if (cardNum.match(this.aRe) != null) {
      return "AM";
    }
    else if (cardNum.match(this.dRe) != null) {
      return "DS";
    }
  }
}

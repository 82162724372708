<div class="container body-content">
  <div *ngIf="payView==true && authPass==true" class="row center-block">
    <div class="col-md-6">
      <div class="panel panel-default details">
        <div class="panel-heading text-center">
          <h3 class="panel-title">
            <b>Event Details</b>
          </h3>
        </div>
        <div class="panel-body">
          <img src="{{request?.ImageUrl}}" class="img-responsive" />
          <br/>
          <div class="row center-block">
            <div class="col-xs-4 text-right">
              <b>Request No:</b>
            </div>
            <div class="col-xs-8">
              {{ request?.RequestNumber }}
            </div>
          </div>
          <br/>
          <div class="row center-block">
            <div class="col-xs-4 text-right">
              <b>Event Name:</b>
            </div>
            <div class="col-xs-8">
              {{ request?.EventName }}
            </div>
          </div>
          <br/>
          <div class="row center-block">
            <div class="col-xs-4 text-right">
              <b>Event Date:</b>
            </div>
            <div class="col-xs-8">
              {{ request?.EventDateTimeValue }}
            </div>
          </div>
          <br/>
          <div class="row center-block">
            <div class="col-xs-4 text-right">
              <b>Venue Name:</b>
            </div>
            <div class="col-xs-8">
              {{ request?.VenueName }}
            </div>
          </div>
          <br/>
          <div class="row center-block">
            <div class="col-xs-4 text-right">
              <b>Tickets:</b>
            </div>
            <div class="col-xs-8">
              {{ request?.Tickets }}
            </div>
          </div>
          <br/>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="panel panel-default details">
        <div class="panel-heading text-center">
          <h3 class="panel-title">
            <b>Payment Details</b>
          </h3>
        </div>
        <div class="panel-body">
          <form role="form" [formGroup]="paymentForm" class="paymentDetails">
            <img src="/assets/img/card.jpg" class="img-responsive col-md-offset-2 form-margin" />
            <div class="form-group col-md-12 col-xs-12 form-margin">
              <div class="col-xs-4 text-right">
                <b>Card Number:</b>
              </div>
              <div class="col-xs-8">
               <!-- <input id="tokenExIframeDiv" name="tokenExIframeDiv" class="form-control" (blur)="generateAuth('tokenExIframeDiv')" />-->
               <input  id="btnEnterHere" class="form-control" name="btnEnterHere" type="text" (click)="generateAuth('tokenExIframeDiv')"/>
                 <div id="tokenExIframeDiv" name="tokenExIframeDiv" style="width: 300px; height: 50px;" hidden></div>
              </div>
            </div>
            <div class="form-group col-md-12 col-xs-12 form-margin frameDiv">
              <div class="col-xs-4 text-right">
                <b>Expiration:</b>
              </div>
              <div class="col-xs-8">
                <div class="col-xs-3" style="padding-left:0px; padding-right:0px;">
                  <input name="expiryMonth" type="number" placeholder="MM" class="form-control" id="expiryMonth" min="1" max="12" maxlength="2" (blur)="validateExpMonth($event.target.value)">
                </div>
                <div class="col-xs-5">
                    <input name="expiryYear" type="number" placeholder="YYYY" class="form-control" id="expiryYear" maxlength="4" minlength="4" (blur)="validateExpYear($event.target.value)">
                </div>
              </div>
            </div>
            <div class="form-group col-md-10 col-md-offset-1 col-xs-12 form-margin">
              <button id="btnSubmit" class="btn btn-success btn-lg btn-block" type="button" (click)="savePayment()">Submit</button>
              <button id="btnProcessing" class="btn btn-success btn-lg btn-block" hidden><i class="fa fa-spinner fa-spin"></i> Processing</button>              
            </div>  
            <div class="form-group col-md-10 col-md-offset-1 col-xs-12 form-margin">
              <div class="col-md-12" hidden id="divError" name="divError" style="text-align: center; background-color: #FFBABA; color: #D8000C; height: auto; border-radius: 4px">
                <label id="lblMsg" name="lblMsg" style="color: #D8000C;text-align: center">Verify Card Number. Expiry Date must be greater than Event Date!</label>
              </div>
            </div>          
          </form>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="payView==true && authPass==false" class="row center-block" align="center">
    <span>
      <img src="/assets/img/warning.png" class="img-responsive confirm-text" />
      <h3 class="confirm-text">
        <b>Invalid URL!!!</b>
      </h3>
    </span>
  </div>
</div>

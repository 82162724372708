import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Params } from '@angular/router';
import { PaymentService } from "app/services/payment.service";
import { RequestService } from "app/services/request.service";
import { IRequest } from "app/services/models/request.model";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  status: string;
  private sub: any;
  private qsub: any;
  private requestnumber: string;
  private auth: string;
  private request: IRequest;
  authPass: boolean;

  constructor(private paymentService: PaymentService,
    private requestService: RequestService,
    private route: ActivatedRoute) { }

  pmtSuccess: boolean;

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.requestnumber = params['reqnum']; // (+) converts string 'id' to a number
      // this.auth = params['auth'];
    });

    this.qsub = this.route.queryParams.subscribe(params => {
      // this.requestnumber = params['reqnum']; // (+) converts string 'id' to a number
      this.auth = params['auth'];
    });

    this.requestService.getRequest(this.requestnumber)
      .subscribe(res => {
        this.request = res;
        if (this.auth != this.request.Token) {
          this.authPass = false;
        }
        else {
          this.authPass = true;
          this.paymentService.getPayment(this.requestnumber).subscribe(
            data => {
              this.pmtSuccess = true;
            },
            error => {
              this.pmtSuccess = false;
            });
        }
      },
      error => {
        this.authPass = false;
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.qsub.unsubscribe();
  }
}

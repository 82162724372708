import { Component, OnInit } from '@angular/core';
import { environment } from "environments/environment";

@Component({
  selector: 'fw-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnInit {

  version: string;
  msgurl: string;

  constructor() {
    this.version = environment.version;
    this.msgurl = environment.msgurl;
  }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthorizeService } from "app/services/authorize.service";
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthorizeGuard implements CanActivate {

  constructor(private authService: AuthorizeService,
    private route: ActivatedRoute,
    private router: Router) { }
    profile = {};

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var result = this.authService.checkAuthorization();
    if (result) {
      return true;
    }
    else {
      this.router.navigate(['/error']);
    }
    return false;
  }
}

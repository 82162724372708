import { Injectable } from '@angular/core';
import { HttpclientService } from "app/services/httpclient.service";
import { IPayment } from "app/services/models/payment.model";
import { Observable } from "rxjs/Observable";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "environments/environment";

@Injectable()
export class PaymentService {

  private baseUrl: string = "";
  private apikey: string = "";
  private pmtSuceess: boolean;
  
  constructor(private httpClientService: HttpclientService,
    private router: Router) {
    this.baseUrl = environment.requestixapiurl;
    this.apikey = environment.requestixapikey;
  }

  payment: IPayment;

  postPayment(pmtInfo: IPayment) : Observable<boolean> {
    return this.httpClientService.post(this.baseUrl + `api/payments/post` + "?apikey=" + this.apikey, pmtInfo);
  }

  getPayment(requestNumber: string): Observable<IPayment> {
    return this.httpClientService.get(this.baseUrl + `api/payments/get?id=` + requestNumber + "&apikey=" + this.apikey);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body;
  }

  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
}

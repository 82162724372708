import { Component, OnInit, AfterViewInit,  OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { ValidationService } from "app/services/validation.service";
import { AuthorizeService } from "app/services/authorize.service";
import { AuthorizeGuard } from "app/guards/authorize.guard";
import { ActivatedRoute, Router } from '@angular/router';
import { IRequest } from "app/services/models/request.model";
import { RequestService } from "app/services/request.service";
import { IPayment } from "app/services/models/payment.model";
import { PaymentService } from "app/services/payment.service";
import { VaultService } from 'app/services/vault.service';
//import { environment } from "environments/environment";

declare var $: any;


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [
    ValidationService
  ]
})
export class PaymentComponent implements OnInit, OnDestroy {
  public paymentForm: FormGroup;
  public submitted: boolean;
  public events: any[] = [];
  private requestnumber: string;
  private token: any;
  private exp_year: any;
  private exp_month: any;
  private card_type: any;
  private cdNum: any;
  private auth: string;
  private sub: any;
  private qsub: any;
  authPass: boolean;
  payView: boolean = false;
  private successVault: any;
  private failureVault: any;
  //private baseUrl: string = "";

  constructor(private _fb: FormBuilder,
    private validationService: ValidationService,
    private authorizeService: AuthorizeService,
    private requestService: RequestService,
    private paymentService: PaymentService,
    private vaultService: VaultService,
    private route: ActivatedRoute,
    private router: Router) { 
      //this.baseUrl = environment.requestixapiurl;
    }
    

  request: IRequest = {
    EventDateTime: "",
    EventDateTimeValue: "",
    EventName: "",
    RequestNumber: "",
    WillCall: "",
    Tickets: "",
    Token: "",
    ImageUrl: "",
    Status: "",
    VenueName: ""
  };
  payment: IPayment = {
    CardNumber: '',
    CardTypeId: '',
    ExpiryMonth: '',
    ExpiryYear: '',
    CardType: '',
    Token: '',
    RequestNumber: '',
    Status: ''
  };

  ngOnInit() {

    this.failureVault = (() => {
      var btnSub = document.getElementById("btnSubmit");
      btnSub.removeAttribute("disabled");
    });

    //this.vaultService.addEventListeners(this.successVault, this.failureVault);

    // we will initialize our model here.
    const cardNoRegex = `^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$`;
    const monthRegex = "^(0?[1-9]|1[012])$";
    const yearRegex = "^(201[7-9]d|20[9]d|2099)$/"

    this.paymentForm = new FormGroup({
      cardNumber: new FormControl('', [Validators.required]),
      tokenExIframeDiv: new FormControl('tokenExIframeDiv'),
      expiry: new FormGroup({
        expiryMonth: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern(monthRegex)]),
        expiryYear: new FormControl('', [Validators.required, Validators.minLength(4)])
      }, this.checkExpiryDate.bind(this))
    });

    this.sub = this.route.params.subscribe(params => {
      this.requestnumber = params['reqnum']; // (+) converts string 'id' to a number
    });

    this.qsub = this.route.queryParams.subscribe(params => {
      this.auth = params['auth'];
    });

    this.requestService.getRequest(this.requestnumber)
      .subscribe(res => {
        this.request = res;
        if (this.request.EventDateTime) {
          this.request.EventDateTimeValue = this.request.EventDateTime.replace(" ", " @ ");
        }
        if (this.auth != this.request.Token) {
          this.authPass = false;
          this.payView = true;
        }
        else {
          this.authPass = true;
          this.paymentService.getPayment(this.requestnumber).subscribe(
            data => {
              this.router.navigate(['/confirmation/' + this.requestnumber], { queryParams: { auth: this.auth } });
            },
            error => {
              this.payView = true;
              //this.vaultService.loadIframe(this.request.EventDateTime);
            });
        }
      },
      error => {
        this.authPass = false;
        this.payView = true;
      });
  }
generateAuth(tokenExIframeDiv: string){
  const divError = document.getElementById("divError");
  divError.setAttribute("hidden","hidden");

  var btnEnterHere = document.getElementById("btnEnterHere");
  btnEnterHere.setAttribute("hidden","hidden");

  var divEnterHere = document.getElementById("tokenExIframeDiv");
  divEnterHere.removeAttribute("hidden");

  this.vaultService.getIframeConfig().subscribe(res => {
    if ((!res || !res.ClientSecretKey)) {
      throw new Error('No token configuration returned')
      // @ts-ignore
    } else if (!window || !window.TokenEx) {
      throw new Error('Tokenex script not found')
    } else {
      // @ts-ignore
      const tokenex = window.TokenEx
      const iframeConfig = {
        origin: res.Origin,
        timestamp: res.TimeStamp,
        tokenExID: res.TokenExID,
        tokenScheme: res.TokenScheme,
        authenticationKey: res.AuthenticationKey,
        pci: true,
        enablePrettyFormat: true,
        enableValidateOnBlur: true,
        enableValidateOnKeyUp: true,
        styles: {
            base: [
              'width: 90%;',
              'max-width: 250px;',
              'padding-left: 14px;',
              'padding-right: 14px;',
              'height: 30px;',
              'border: 1px solid #bbb;',
              'border-radius: 4px;',
              'font-size: 14px;',
            ].join(' '),
            error: ['border: 1px solid #d32f2f; border-radius: 4px;'].join(' '),
            focus: ['border:1px solid blue;border-radius:4px;padding:6px 12px;font-size:14px;color:#555;background-color:#fff;vertical-align:middle;'],
          },
      }
      const creditCardTypeMap = {
        masterCard: 'MC',
        americanExpress: 'AM',
        discover: 'DS',
        visa: 'VI',
      }
      
      const iframe = new tokenex.Iframe('tokenExIframeDiv', iframeConfig)
      if (iframe) {
        iframe.on('blur', () => {
          iframe.tokenize()
        })

        iframe.on(
          'validate',
          (data: { cardType: string; firstSix: string; isValid: string; lastFour: string }) => {
            if (divError) {
              divError.setAttribute("hidden","hidden");
            }
            if (
              data.cardType &&
              typeof data.cardType === 'string' &&
              creditCardTypeMap[data.cardType]
            ) {
              this.card_type = creditCardTypeMap[data.cardType]
            }
            if (!data.isValid && !!this.token) {
              this.token = ''
            }
            if (!data.isValid && !!divError) {
              divError.removeAttribute("hidden")
            }
          }
        )

        iframe.on('tokenize', (data: any) => {
          if (data.token) {
            if (divError) {
              divError.setAttribute("hidden","hidden");
            }
            this.token = data.token;            
            this.card_type = data.cardType;
          }
        })

        iframe.on('load', () => {
          iframe.focus()
        })

        iframe.load()
      } else {
        throw new Error('No token configuration returned')
      }
    }
  },
  error => {
    console.log("Error: " + error);
    const divError = document.getElementById("divError");
    if (divError) {
      divError.removeAttribute("hidden")
    }
  });
  
}
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /*checkCard(control: FormControl) {
    return this.validationService.validateCard(control.value) ? null : { valid: false };
  }*/

  checkExpiryDate(group: FormGroup) {
    return this.validationService.validateExpiry(group.get('expiryMonth').value, group.get('expiryYear').value, this.request.EventDateTime)
      ? null : { valid: false };
  }

  savePayment() {
    var divError = document.getElementById("divError");
    divError.setAttribute("hidden","hidden");
    
    this.exp_month = $('#expiryMonth').val();
    this.exp_year = $('#expiryYear').val();
    // this.card_type = this.validationService.GetCardType(this.cdNum);
    var currentDate = new Date(this.request.EventDateTime);
    var expiry = new Date(this.exp_year, this.exp_month);
    var goodDate = currentDate <= expiry;

    if(this.token && this.exp_year && this.exp_month && this.card_type && goodDate)
    {
      var btnSub = document.getElementById("btnSubmit");
      btnSub.setAttribute("disabled", "disabled");
      this.payment.CardType = this.card_type;
      this.payment.Token = this.token;
      this.payment.ExpiryMonth = this.exp_month;
      this.payment.ExpiryYear = this.exp_year;
      this.payment.RequestNumber = this.request.RequestNumber;
      this.payment.Status = "Submitted";

      this.paymentService.postPayment(this.payment).subscribe(
        data => {
          this.router.navigate(['/confirmation/' + this.requestnumber], { queryParams: { auth: this.auth } });
        },
        error => {
          console.log("Error Submitting PaymentInfo");
        });
    }
    else{
      divError.removeAttribute("hidden");
    }
  }

  /*tokenize(ccNumber: string){
    this.vaultService.getToken(ccNumber)
    .subscribe(res => {
      this.token = res["token"];
      this.cdNum =  ccNumber;    
    },
    error => {
      console.log("Error: ");
    });
  }*/

  validateExpMonth(expMon: any){
    if (Number(expMon) >= 13 || Number(expMon) < 1 || expMon.length > 2) 
        {
          $('#expiryMonth').val('');
        }
  }
  validateExpYear(expYr: any) {
    var today = new Date();
    var todayYear = today.getFullYear();
    if (Number(expYr) < todayYear || expYr.length > 4) 
    {
      $('#expiryYear').val('');
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FwModule } from "fw/fw.module";
import { PaymentComponent } from "app/components/payment/payment.component";
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { AuthorizeGuard } from "app/guards/authorize.guard";
import { AuthorizeService } from "app/services/authorize.service";
import { ErrorComponent } from './components/error/error.component';
import { RequestService } from "app/services/request.service";
import { HttpclientService } from "app/services/httpclient.service";
import { PaymentService } from "app/services/payment.service";
import { VaultService } from 'app/services/vault.service';

@NgModule({
  declarations: [
    AppComponent,
    PaymentComponent,
    ConfirmationComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    FwModule,
    ReactiveFormsModule
  ],
  providers: [AuthorizeGuard,
    AuthorizeService,
    RequestService,
    PaymentService,
    HttpclientService,
    VaultService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

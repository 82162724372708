<div style="text-align:center; color:rgb(3,87,157);">
    <div>
        <div style="color:gray">
            Copyright &copy;
            <script>
                document.write(new Date().getFullYear())
            </script> Madison Square Garden. All Rights Reserved
        </div>
        <div>
            <span style="color:gray">RequesTix | Version : {{ version }} |</span>
            <a class="footerLink" href="{{ msgurl }}">Madison Square Garden Website</a>
        </div>
    </div>
</div>
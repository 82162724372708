import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

@Injectable()
export class HttpclientService {

  private headers: Headers;
  private options: RequestOptions;

  constructor(private http: Http) {
    this.headers = new Headers({ 'Content-Type': 'application/json' });
    this.options = new RequestOptions({ headers: this.headers });
  }

  get(url) {
    let headers = new Headers();
    return this.http.get(url, this.options).map(res => res.json());
  }

  post(url, data) {
    let body = JSON.stringify(data);
    return this.http.post(url, body, this.options).map(res => res.json());
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Http, Headers, RequestOptions } from '@angular/http';
import { IVault } from 'app/services/models/vault.model';
import { IParameter } from 'app/services/models/parameter.model';
import { HttpclientService } from "app/services/httpclient.service";
import { environment } from "environments/environment";

declare var myVaultObj: any;
declare var myEventObj: any;
declare var myEventValueObj: any;

@Injectable()
export class VaultService {
    private headers: Headers;
    private options: RequestOptions;
    private priorToDate: string = "";
    private msg: string = "message";

    private baseUrl: string = "";
    private apikey: string = "";
    private vaulttokenurl: string = "";
    private sessionId: string = "";
    private cardtypes: string = "";
  
    constructor(private httpClientService: HttpclientService) {
      this.baseUrl = environment.requestixapiurl;
      this.apikey = environment.requestixapikey;
    }

    getToken(ccNumber: string): Observable<string> {
      return this.httpClientService.get(this.baseUrl + "api/vaults/TokenizeAsync?data=" + ccNumber + "&apikey=" + this.apikey);
    }

    getIframeConfig(): Observable<{
      TokenExID: string
      TokenScheme: string
      AuthenticationKey: string
      TimeStamp: string
      Origin: string
      PCI: boolean
      ClientSecretKey: string
      ConcatenatedString: string
    }> 
    {
      return this.httpClientService.get(this.baseUrl + "api/vaults/GetiFrameConfig_Guest?apikey=" + this.apikey);
    }

    submitPayment()
    {
        myVaultObj.validateCard();
    }
}

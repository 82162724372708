import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { IRequest } from "app/services/models/request.model";
import { HttpclientService } from "app/services/httpclient.service";
import { environment } from "environments/environment";

@Injectable()
export class RequestService {

  private baseUrl: string = "";
  private apikey: string = "";

  constructor(private httpClientService: HttpclientService) {
    this.baseUrl = environment.requestixapiurl;
    this.apikey = environment.requestixapikey;
  }

  request: IRequest;

  getRequest(requestNumber: string): Observable<IRequest> {
    return this.httpClientService.get(this.baseUrl + `api/requests/get?id=` + requestNumber + "&apikey=" + this.apikey);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body;
  }

  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
}

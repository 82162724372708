import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class AuthorizeService {

  constructor(
  ) { }

  checkAuthorization() {
    return true;
    // return this.http.get(`https://conduit.productionready.io/api/profiles/eric`)
    //   .map((res: Response) => res.json()
    //   // {
    //   //   console.log(res.json);
    //   //   res.json();
    //   //   console.log("I CAN SEE DATA HERE: ", res.json());
    //   //   return res.json();
    //   // }
    // );
  }
}
